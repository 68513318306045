//REGISTER
export const POST_LOGIN_TOKEN = "/login"
export const LOG_OUT = "/logout"
export const RESET_PASSWORD = '/account-reset'
export const RESET_CONFIRM = '/account-confirm'
export const VALIDA_TOKEN = '/account-valida-token'

//HOME
export const GET_DATA_HOME = '/cms/home'
//uSERS
export const USERS_RESOURCE = '/users'
//clients
export const CLIENTS_RESOURCE = '/transport-office/clients'
export const CLIENTS_COMPANY_RESOURCE ='/cms/companies'
// plans
export const PLANS_RESOURCE = '/cms/plans'
export const PLANS_ACTIVATE = '/cms/plan-action'
//PAYMETS
export const PAYMENTS_RESOURCE = '/cms/payments'
//catalogos
export const GET_PREFIXES = '/catalogs-cms/prefixes'
export const GET_MODULES = '/catalogs-cms/models'
export const GET_PAYMENTS_PERIODS = '/catalogs-cms/payment-periods'
export const GET_STATUSES = '/catalogs-cms/statuses'
export const GET_TYPES = '/catalogs-cms/types'
export const MODELS_TRUCK_TRAILER_RESOURCE = '/catalogs-cms/models-trucks-trailer'
export const FABRICANTS_RESOURCE = '/catalogs-cms/fabricants'
export const GET_SCHEDULS = '/catalogs-cms/scheduls'
//TRAILER_TRUCKS_RESOURCE
export const TRAILER_TRUCKS_RESOURCE = '/transport-office/trucks-trailer'
//Load
export const LOADS_RESOURCE  = '/transport-office/loads'

export const GET_INTERESES = '/catalogos-registro/intereses'
export const GET_ESPECIALIDADES = '/catalogos-registro/especialidades'
export const GET_CUIDADOS = '/catalogos-registro/cuidados'
export const GET_ENTIDADES = '/catalogos-registro/entidades'
export const GET_HABILIDADES = '/catalogos-registro/habilidades'
export const GET_OFERTA_SERVICIOS = '/catalogos-registro/oferta-servicios'
export const GET_TIEMPOS= '/catalogos-registro/turnos-tiempos'






