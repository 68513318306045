import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clientsCompanyResource, getPrefixes, plansResource } from "../../../helpers/configRoutes/backend_helper";
import { useSelector } from "react-redux";
import { validatePhones } from "../../../helpers/forms/Validation";
import CustomInput from "../../../components/form/CustomInput";
import { showFormErrors } from "../../../helpers/showFormErrors";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import CustomSelect from "../../../components/form/CustomSelect";
import CustomButton from "../../../components/form/CustomButton";
import CustomButtonOval from "../../../components/form/CustomButtonOval";
import { useNavigate } from "react-router-dom";

const ProfileAccountForm = ({ modal, setModal }) => {
    const user = useSelector((state) => state.Login.user);
    const [company, setCompany] = useState({})
    const [loading, setLoading] = useState(false);
    const [prefixes, setPrefixes] = useState([]);
    const [plans, setPlans] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const getDataCompany = async () => {
            const response = await clientsCompanyResource('show', {}, user.company_id);
            if (response.status) {
                Object.keys(response.company).forEach(field => {
                    validation.setFieldValue(field, response.company[field], false); // Setear valores en el formik sin disparar validación
                });
            }
        }
        getDataCompany();

        const getCatalogs = async () => {
            const response = await plansResource('get', { params: { select: true } })
            setPlans(response.plans)
        }
        const getPrefixesCatalog = async () => {
            const response = await getPrefixes();
            if (response.status) {
                setPrefixes(response.prefixes);
            }
        }
        getCatalogs()
        getPrefixesCatalog();
    }, []);

    const validation = useFormik({
        initialValues: company,
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email').required('Email is required'),
            name: Yup.string().required('Name is required'),
            prefix_id: Yup.string().required('Prefix is required'),
            phone: validatePhones(true),
            contact_name: Yup.string().required('Contact name is required'),
            address: Yup.string().required('Address is required'),
            invoicing_address: Yup.string().required('Invoicing address is required'),
            dot: Yup.string().required('DOT is required'),
            mc_number: Yup.string().required('MC number is required')
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await clientsCompanyResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                setLoading(false)
                setModal({...modal,show:false})
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });
    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}>
                    <div className="grid grid-cols-2 gap-4 p-4">
                        <CustomInput
                            label="Company Name"
                            type="text"
                            name="name"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.name}
                            value={validation?.values?.name}
                            handleChange={validation.handleChange}
                        />
                        <div className="grid grid-cols-2">
                            <CustomSelect
                                label="Subscription Type"
                                options={plans}
                                name="plan_id"
                                disabled={true}
                                labelClassName={'text-sm text-[#ADADAD] whitespace-nowrap'}
                                value={validation?.values?.plan_id}
                            />
                            {!modal.profile &&
                                <p
                                    onClick={() => navigate('/subscriptions')}
                                    className="text-mts-blue-primary text-sm ml-3 mt-5 cursor-pointer hover:underline"
                                >
                                    Upgrade or downgrade plan?
                                </p>
                            }
                        </div>

                        <CustomInput
                            label="Contact Name"
                            type="text"
                            name="contact_name"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.contact_name}
                            value={validation?.values?.contact_name}
                            handleChange={validation.handleChange}
                        />
                        <div className="grid grid-cols-2">
                            <CustomSelect
                                label="Phone Prefix"
                                options={prefixes}
                                name="prefix_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.prefix_id}
                                value={validation?.values?.prefix_id}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Phone Number"
                                type="text"
                                name="phone"
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.phone}
                                value={validation?.values?.phone}
                                handleChange={validation.handleChange}
                            />
                        </div>
                        <CustomInput
                            label="Email Address"
                            type="text"
                            name="email"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.email}
                            value={validation?.values?.email}
                            handleChange={validation.handleChange}
                        />

                        <CustomInput
                            readOnly={true}
                            label="Useres / Licences"
                            type="text"
                            name="users"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            // error={validation?.errors?.company_name}
                            value={validation?.values?.users_count}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Address"
                            type="text"
                            name="address"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.address}
                            value={validation?.values?.address}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Invoicing Address"
                            type="text"
                            name="invoicing_address"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.invoicing_address}
                            value={validation?.values?.invoicing_address}
                            handleChange={validation.handleChange}
                        />
                        {modal.profile ?
                            <>
                                <CustomInput
                                    label="DOT"
                                    type="number"
                                    name="dot"
                                    labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                    error={validation?.errors?.dot}
                                    value={validation?.values?.dot}
                                    handleChange={validation.handleChange}
                                />
                                <CustomInput
                                    label="MC Number"
                                    type="number"
                                    name="mc_number"
                                    labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                    error={validation?.errors?.mc_number}
                                    value={validation?.values?.mc_number}
                                    handleChange={validation.handleChange}
                                />
                            </>
                            :
                            <div className="grid grid-cols-1">
                                <CustomInput
                                    label="Payment Method"
                                    type="text"
                                    name="payment_id"
                                    labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                    error={validation?.errors?.payment_id}
                                    value={'VISA ending in **25'}
                                    handleChange={validation.handleChange}
                                />
                                <CustomButtonOval
                                    loading={loading}
                                    textLoad={'Saving'}
                                    type={"success"}
                                    className="w-full lg:w-[100%] bg-[#3749A6]"
                                    onClick={()=>navigate('/accounting')}
                                >
                                    {'Payment History'}
                                </CustomButtonOval>
                            </div>
                        }
                    </div>

                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            loading={loading}
                            textLoad={'Saving'}
                            type={"success"}
                            className="w-full lg:w-[30%]"
                        >
                            {validation.values?.id ? 'Save' : 'Add new'}
                        </CustomButton>
                    </div>
                </form>
            </BaseModalAnimated >
        </>
    )
}

export default ProfileAccountForm;