import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomSelect from "../../components/form/CustomSelect";
import { getStatuses } from "../../helpers/configRoutes/backend_helper";


const AddStopsModal = ({ modal, setModal, validation }) => {
    const [statuses, setStatuses] = useState([])
    const validationStops = useFormik({
        initialValues: {
            name: '',
            order: '',
            status: {},
            status_id: '',
            mile: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            order: Yup.string().required('Order is required'),
            status_id: Yup.string().required('Status is required'),
        }),
        onSubmit: async (values, { setErrors, resetForm }) => {
            const lastArray = validation.values.stops;
            let newArray = [...lastArray, values];

            // Ordena el array por el campo `order` en orden ascendente
            newArray.sort((a, b) => a.order - b.order);

            // Ajusta los valores de `order` para que sean únicos
            newArray = newArray.map((item, index) => ({
                ...item,
                order: index + 1  // Asigna valores únicos y consecutivos a `order`
            }));

            // Actualiza el array `stops` en Formik con el array ordenado y ajustado
            validation.setFieldValue('stops', newArray);

            // Cierra el modal
            setModal({ ...modal, show: false });

            // Resetea el formulario después de guardar
            resetForm();
        }
    });

    useEffect(() => {
        const getCatalogs = async () => {
            const statusesResponse = await getStatuses({ params: { model: 'Stop' } });
            setStatuses(statusesResponse.statuses); // Descomentar si deseas actualizar los statuses de la API
        };
        getCatalogs();
    }, []);

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validationStops.handleSubmit();
                    showFormErrors(validationStops);
                    return false;
                }}>
                    <div className="grid grid-cols-2 gap-4 p-2">
                        <CustomInput
                            label="Order"
                            type="number"
                            name="order"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validationStops?.errors?.order}
                            value={validationStops?.values?.order}
                            handleChange={validationStops.handleChange}
                        />
                        <CustomInput
                            label="Name"
                            type="text"
                            name="name"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validationStops?.errors?.name}
                            value={validationStops?.values?.name}
                            handleChange={validationStops.handleChange}
                        />
                        <CustomSelect
                            label="Status"
                            options={statuses}
                            name="status_id"
                            labelClassName={'text-base text-[#ADADAD]'}
                            className={'mr-2'}
                            error={validationStops?.errors?.status_id}
                            value={validationStops?.values?.status_id}
                            handleChange={(e) => {
                                const selectedStatus = statuses.find((s) => s.id === parseInt(e.target.value, 10));
                                validationStops.setFieldValue('status', selectedStatus);
                                validationStops.setFieldValue('status_id', e.target.value);
                            }}
                        />
                        <CustomInput
                            label="Mile"
                            type="number"
                            name="mile"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validationStops?.errors?.mile}
                            value={validationStops?.values?.mile}
                            handleChange={validationStops.handleChange}
                        />
                    </div>

                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            type={"success"}
                            className="w-full lg:w-[30%]"
                        >
                            Add Stop
                        </CustomButton>
                    </div>
                </form>
                
            </BaseModalAnimated >
        </>
    );
};

export default AddStopsModal;
