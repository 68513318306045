import React, { useState } from 'react';
import { dangerToast, warningToast } from '../../helpers/Toast'

const FileInputDocuments = ({ onChange, format, nameInput = 'Edit Photo ', withClass = 'w-full', textButton = true, validation, name }) => {
  const [fileName, setFileName] = useState('');

  // Función para validar el formato del archivo
  const validateFormat = (file) => {
    if (format === 'pdf') {
      return file.type === 'application/pdf';
    } else if (format === 'img') {
      return file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg';
    } else if (format == 'xml') {
      return file.type === 'text/xml';
    }

    return false;
  };

  // Función para validar el tamaño del archivo
  const validateSize = (file) => {
    if (file.size <= 1024 * 1024) {
      return true
    }

    return false
  };


  const handleFileChange = async (archivo) => {
    const file = archivo.target.files[0];

    // Verificar si el archivo es de un formato permitido
    if (!validateFormat(file)) {
      dangerToast('You can only select files format: ' + (format == 'img' ? 'png, jpeg, jpg' : format));
      return;
    }

    // Verificar el tamaño del archivo
    if (!validateSize(file)) {
      dangerToast('Files up to 1MB');
      return;
    }

    setFileName(file.name);

    try {
      const base64 = await getBase64(file);
      const formato = file.type
      let item = {
        id: validation.values.evidences.length + 1,
        name: file.name,
        format_name: `${name}_format`,
        format: formato,
        url: base64
      }
      const actualArray = validation.values.evidences
      let newArray = [...actualArray, item]

      validation.setFieldValue(`evidences`, newArray)
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  }

  const getBase64 = (archivo) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <>
      <label className="text-mts-blue-primary  ml-3 mt-0 cursor-pointer hover:underline mb-0">
        +  {nameInput}
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
      </label>
    </>
  );
};

export default FileInputDocuments;