import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clientsResource, fabricantsResource, getTypes, modelsTruckTrailerResource, trailerTrucksResource } from "../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomSelect from "../../components/form/CustomSelect";
import { validatePhones } from "../../helpers/forms/Validation";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import "react-datepicker/dist/react-datepicker.css";
import AddCatalogsModal from "./AddCatalogsModal";

const AddUpdTrailerTruckModal = ({ modal, setModal, trailerTruckItem, setFilters, filters }) => {
    const navigate = useNavigate()
    const [models, setModels] = useState([]);
    const [types, setTypes] = useState([])
    const [trucks, setTrucks] = useState([])
    const [fabricants, setFabricants] = useState([])
    const [modalCatalog,setModalCatalog]=useState({show:false,title:'Fabricant'})

    const [loading, setLoading] = useState(false)
    const baseitem = {
        id: null,
        number: '',
        year: '',
        vin: '',
        ownership: '',
        plate_usd: '',
        expiration_usd: '',
        plate_mx: '',
        expiration_mx: '',
        inspection_date: '',
        expiration_assurance: '',
        type_id: '',
        model_id: '',
        company_id: '',
        operator_id: '',
        truck: modal.truck
    }

    const validation = useFormik({
        initialValues: trailerTruckItem,
        validationSchema: Yup.object({
            number: Yup.number()
                .typeError("The trailer number must be a number")
                .required("Trailer number is required"),

            year: Yup.number()
                .typeError("The year must be a number")
                .integer("The year must be a valid integer")
                .min(1900, "Year must be after 1900")
                .max(new Date().getFullYear(), `Year must not be in the future`)
                .required("Year is required"),

            vin: Yup.string()
                .required("VIN number is required"),

            // Validación personalizada para asegurarse de que al menos una placa y su expiración estén presentes
            plate_usd: Yup.string().nullable(),
            expiration_usd: Yup.date()
                .nullable()
                .when('plate_usd', {
                    is: (val) => !!val,
                    then: (schema) => schema.required('USD expiration date is required when USD plate is filled'),
                    otherwise: (schema) => schema.nullable(),
                }),
            plate_mx: Yup.string().nullable(),
            expiration_mx: Yup.date().nullable(),
            expiration_mx: Yup.date()
                .nullable()
                .when('plate_mx', {
                    is: (val) => !!val,
                    then: (schema) => schema.required('MX expiration date is required when MX plate is filled'),
                    otherwise: (schema) => schema.nullable(),
                }),
            inspection_date: Yup.date()
                .required("Inspection date is required"),

            expiration_assurance: Yup.date()
                .required("Assurance expiration date is required")
                .min(new Date(), "Assurance expiration date must be a future date"),

            type_id: Yup.string()
                .required("Type is required"),

            model_id: Yup.string()
                .required("Model is required"),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await trailerTrucksResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                setLoading(false)
                setFilters({ ...filters, search: filters.search })
                setModal({ ...modal, show: false })

            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });

    useEffect(() => {
        if (trailerTruckItem.id) {
            validation.setValues(trailerTruckItem); // Actualizar los valores del formulario cuando `client` cambia
        } else {
            validation.resetForm({ values: baseitem }); // Resetea el formulario a los valores por defecto si no hay cliente
        }
    }, [modal]);

    useEffect(() => {
        const getCatalogs = async () => {
            const typesResponse = await getTypes('get', { params: { select: true } });
            setTypes(typesResponse.types)
            const fabricantsResponse = await fabricantsResource('get', { params: { select: true, fabricant_id: validation.values.model_id } });
            setFabricants(fabricantsResponse.fabricants)
            const trucksTrailerResponse = await trailerTrucksResource('get', { params: { select: true, truck: !modal.truck } });
            setTrucks(trucksTrailerResponse.items)
        }
        getCatalogs()
    }, []); 

    useEffect(() => {
        const getModels = async () => {
            const modelsResponse = await modelsTruckTrailerResource('get', { params: { select: true, fabricant_id: validation.values.fabricant_id } });
            setModels(modelsResponse.models)
        }
        if (validation.values.fabricant_id) {
            getModels()
        }

    }, [validation.values.fabricant_id]);

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}>
                    <div className="grid grid-cols-2 gap-4 p-2">
                        <CustomInput
                            label="Trailer Number"
                            type="text"
                            name="number"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.number}
                            value={validation?.values?.number}
                            handleChange={validation.handleChange}
                        />
                        {modal.truck ?
                            <div className="grid grid-cols-2">
                                <CustomSelect
                                    label="Trailer"
                                    options={trucks}
                                    name="trailer_id"
                                    labelClassName={'text-base text-[#ADADAD]'}
                                    className={'mr-2'}
                                    error={validation?.errors?.trailer_id}
                                    value={validation?.values?.trailer_id}
                                    handleChange={validation.handleChange}
                                />
                                <p
                                    onClick={() => navigate('/trailers')}
                                    className="text-mts-blue-primary text-sm ml-3 mt-3 cursor-pointer hover:underline"
                                >
                                    +Add new Trailer
                                </p>
                            </div>
                            :
                            <div className="grid grid-cols-2">
                                <CustomSelect
                                    label="Truck"
                                    options={trucks}
                                    name="truck_id"
                                    labelClassName={'text-base text-[#ADADAD]'}
                                    className={'mr-2'}
                                    error={validation?.errors?.truck_id}
                                    value={validation?.values?.truck_id}
                                    handleChange={validation.handleChange}
                                />
                                <p
                                    onClick={() => navigate('/trucks')}
                                    className="text-mts-blue-primary text-sm ml-3 mt-3 cursor-pointer hover:underline"
                                >
                                    +Add new Truck
                                </p>
                            </div>
                        }
                        <div className="grid grid-cols-2">
                            <CustomSelect
                                label="Make"
                                options={fabricants}
                                name="fabricant_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.fabricant_id}
                                value={validation?.values?.fabricant_id}
                                handleChange={validation.handleChange}
                            />
                            <p
                                onClick={() => setModalCatalog({show:true,title:'Fabricant'})}
                                className="text-mts-blue-primary text-sm ml-3 mt-3 cursor-pointer hover:underline"
                            >
                                +Add new Fabricant
                            </p>
                        </div>
                        <div className="grid grid-cols-2">
                            <CustomSelect
                                label="Model"
                                options={models}
                                name="model_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.model_id}
                                value={validation?.values?.model_id}
                                handleChange={validation.handleChange}
                            />
                            <p
                                onClick={() => setModalCatalog({show:true,title:'Model'})}
                                className="text-mts-blue-primary text-sm ml-3 mt-3 cursor-pointer hover:underline"
                            >
                                +Add new Model
                            </p>
                        </div>
                        <CustomSelect
                            label="Type"
                            options={types}
                            name="type_id"
                            labelClassName={'text-base text-[#ADADAD]'}
                            className={'mr-2'}
                            error={validation?.errors?.type_id}
                            value={validation?.values?.type_id}
                            handleChange={validation.handleChange}
                        />
                        <div className="grid grid-cols-2">
                            <CustomInput
                                label="Year"
                                type="number"
                                name="year"
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.year}
                                value={validation?.values?.year}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Vin Number"
                                type="number"
                                name="vin"
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.vin}
                                value={validation?.values?.vin}
                                handleChange={validation.handleChange}
                            />
                        </div>


                        <CustomInput
                            label="Plate MX"
                            type="text"
                            name="plate_mx"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.plate_mx}
                            value={validation?.values?.plate_mx}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Expiration Plate USA"
                            name="expiration_mx"
                            type={'date'}
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.expiration_mx}
                            value={validation?.values?.expiration_mx}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Plate USA"
                            type="text"
                            name="plate_usd"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.plate_usd}
                            value={validation?.values?.plate_usd}
                            handleChange={validation.handleChange}
                        />

                        <CustomInput
                            label="Expiration Plate USA"
                            name="expiration_usd"
                            type={'date'}
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.expiration_usd}
                            value={validation?.values?.expiration_usd}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Ownership"
                            type="text"
                            name="ownership"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.ownership}
                            value={validation?.values?.ownership}
                            handleChange={validation.handleChange}
                        />
                        <div className="grid grid-cols-2">
                            <CustomInput
                                label="Assurance Expiration"
                                name="expiration_assurance"
                                type={'date'}
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.expiration_assurance}
                                value={validation?.values?.expiration_assurance}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Inspection Date"
                                name="inspection_date"
                                type={'date'}
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.inspection_date}
                                value={validation?.values?.inspection_date}
                                handleChange={validation.handleChange}
                            />
                        </div>


                    </div>

                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            loading={loading}
                            textLoad={'Saving'}
                            type={"success"}
                            className="w-full lg:w-[30%]"
                        >
                            {validation.values?.id ? 'Save' : 'Add new'}
                        </CustomButton>
                    </div>
                </form>
                <AddCatalogsModal modal={modalCatalog} setModal={setModalCatalog} validation={validation} setModels={setModels} setFabricants={setFabricants}/>
            </BaseModalAnimated >
        </>
    )
}
export default AddUpdTrailerTruckModal;