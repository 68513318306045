import React, { useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import PreviewPDF from "../../components/modal/PreviewPdf";

const EvidencesFilesModal = ({ modal, setModal }) => {
    const [modalPrev, setModalPrev] = useState({ show: false, url: '' })

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                    <table className="w-full text-left border border-gray-300 shadow-lg rounded-md">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-gray-600 font-semibold">Name</th>
                                <th className="px-4 py-2 text-gray-600 font-semibold">Size</th>
                                <th className="px-4 py-2 text-gray-600 font-semibold">Format</th>
                                <th className="px-4 py-2 text-gray-600 font-semibold">Date Created</th>
                                <th className="px-4 py-2 text-gray-600 font-semibold">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modal.item?.evidences?.map((f, i) => (
                                <tr key={i} className="hover:bg-gray-100 border-b border-gray-200">
                                    <td className="px-4 py-2">{f.file_name}</td>
                                    <td className="px-4 py-2">{f.size}</td>
                                    <td className="px-4 py-2">{f.format}</td>
                                    <td className="px-4 py-2">{f.created_at}</td>
                                    <td className="px-4 py-2">
                                        <button
                                            type="button"
                                            onClick={() => setModalPrev({ show: true, url: f.url })}
                                            className="flex items-center justify-center px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                        >
                                            <i className="fas fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PreviewPDF modal={modalPrev} setModal={setModalPrev} />
            </BaseModalAnimated >
        </>
    );
};

export default EvidencesFilesModal;
